.intro-outer-container {
    width: 100%;
    height: auto;
    /* border: 3px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.intro-particle-container {
    width: 100%;
    /* border: 2px solid yellow; */
    height: 25vw;
    overflow: hidden;
}

.intro-inner-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0vw;
    margin-top: 9vw;
    background-color: rgb(70, 70, 70);
    border-radius: 10px;
    /* border: 2px solid green; */
} 

#intro-accordion #title {
    color: white;
    font-size: 1.3vw;
    margin-bottom: 0.75vw;
    font-family: 'Anton';
}

#intro-accordion #box {
    margin-bottom: 1.3vw
}

/*Modify poster-title*/
.poster-container .poster-title {
    z-index: 10;
    color: white;
    font-family: 'Roboto Condensed';   
    font-weight: 900;
}

.poster-container .poster-text {
    z-index: 10;
    color: white;
    text-align: center;
    
}

.poster-container {
    position: absolute;
    top: 14vw;
}
/***/

.poster-container .poster-button-holder {
    border-top: 4px solid;
    width: 100%;
    height: auto;
    margin-top: 1vw;
    display: flex;
    justify-content: space-evenly;
}

.poster-container #github-icon {
    text-decoration: none;
    font-size: 2.4vw;
    color: white;
}

.poster-container .poster-github-anchor {
    text-decoration: none;
}

.poster-container .poster-github-button {
    width: 5vw;
    margin-top: 1vw;

    border-radius: 15px;
    background-color: rgba(70, 70, 70, 0.5);
}

.education-info,
.general-info {
    width: 100%;
    min-height: 0vw;
    display: flex;
    /* border: 3px solid red; */
    border-radius: 10px;
    margin-top: 1vw;
    background-color: rgb(70, 70, 70);
}

.education-info-container,
.general-info-container {
    width: 100%;
    /* border: 5px solid green; */
    display:flex;
    align-items: center;
    justify-content: center;
}

.general-info-container {
    flex-direction: column;
}

#introduction-title {
    font-family: 'Anton';
    letter-spacing: 0.1vw;
    color: white;
    font-size: 2vw;
}

#introduction-text {
    font-size: 1.1vw;
    font-family: 'Roboto Condensed';
    color: white;
}

#intro-accordion {
    margin: 1vw;
}

 
@media only screen and (max-width: 1550px) {
    .intro-inner-container {
        /* border: 2px solid blue; */
        margin-top: 10vw;
        width: 60%;
    }

    #introduction-title {
        font-size: 3vw;
       
    }

    #introduction-text {
        font-size: 1.6vw;
    }

    .education-info,
    .general-info {
        margin-top: 5vw;
    }

    #intro-accordion #title {
        color: white;
        font-size: 2.2vw;
        margin-bottom: 1.5vw;
        font-family: 'Anton';
    }
    

    #intro-accordion #box {
        margin-bottom: 3vw
    }
}

@media only screen and (max-width: 450px) {
    .intro-inner-container {
        margin-top: 70vw;
        width: 95%;
        /* border: 1px solid red; */
    }

    .intro-inner-container #intro-accordion {
        width: 100%;
        /* border: 1px solid red; */
    }

    .education-info,
    .general-info {
        width: 100%;
        min-height: 0vw;
        margin-top: 7vw;
        margin-bottom: 7vw;
    }

    #introduction-title {
        font-size: var(--mobile-header-size);
    }

    .general-info #introduction-text,
    .education-info #introduction-text {
        font-size: var(--mobile-font-size);
    }

    .poster-container {
        top: 35vw;
    }

    .poster-container .poster-title {
        font-size: 11vw;
    }

    .poster-container .poster-text {
        font-size: 3.5vw;
    }

    .poster-container #github-icon {
        font-size: 7vw;   
    }

    .poster-container .poster-github-button {
        margin-top: 5vw;
    }

    #intro-accordion #title {
        color: white;
        font-size: 5.2vw;
        margin-bottom: 10w;
        
    }

    #intro-accordion #box {
        margin-bottom: 7vw
    }
}


