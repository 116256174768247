.db-container {
    width: 100%;
    min-height: 0vw;
    display: flex;
    /* border: 3px solid red; */
    border-radius: 10px;
    margin-top: 2vw;
    background-color: rgb(70, 70, 70);
}

.db-inner-container {
    width: 100%;
    /* border: 5px solid green; */
    display:flex;
    align-items: center;
    justify-content: center;
}

#db-accordion #box {
    display: flex;
    flex-direction: column;
}

#db-title {
    font-family: 'Anton';
    letter-spacing: 0.1vw;
    color: white;
}

#db-text {
    font-size: 1.1vw;
    color: white;
    font-family: 'Roboto Condensed';
}

#db-logo {
    color: white;
}

@media only screen and (max-width: 1550px) {
    #db-text {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 450px) {
    #db-title {
        font-size: var(--mobile-header-size);
    }

    #db-text {
        font-size: var(--mobile-font-size);
    }

    #db-accordion {
        width: 100%;
    }
}