.footer-container {
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    justify-content: center;
    position:relative;
    bottom: 1px;
    padding: 10px;
    margin-top: 10vw;
}

#footer-text {
    font-family: 'Quicksand';
    color: white;
    margin-top: 2vw;
    margin-bottom: 2vw;
    font-size: 1.4vw;
}

#copyright-icon {
    color: white;
    font-size: 0.8vw;
}

@media only screen and (max-width: 1550px) {
    .footer-container {
        margin-top: 20vw;
    }
}

@media only screen and (max-width: 450px) {
    .footer-container {
        margin-top: 10vw;
        position: sticky;
        z-index: 1000;
    }

    #footer-text {
        font-size: 4.5vw;
    }

    #copyright-icon  {
        font-size: 3.4vw;
    }
}