body {
    background-color: rgb(70, 70, 70) !important;
}

.tech-outer-container {
    width: 100%;
    height: auto;
    /* border: 3px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.tech-inner-container #header {
    font-size: 2vw;
    font-family: "Anton";
    color: white;
    margin-top: 0vw;
    margin-left: 0vw;
    text-align:center;
    width: 100%;
  
}

.tech-inner-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0.5%;
    margin-top: 0.5%;
    background-color: rgb(70, 70, 70);
    border-radius: 10px;
    /* border: 2px solid red; */
    padding: 3vw;
}

.python-container,
.js-container {
    width: 100%;
    min-height: 0vw;
    display: flex;
    /* border: 3px solid red; */
    border-radius: 10px;
    margin-top: 2vw;
    background-color: rgb(70, 70, 70);
}

.python-inner-container,
.js-inner-container {
    width: 100%;
    /* border: 5px solid green; */
    display:flex;
    align-items: center;
    justify-content: center;
}

#js-accordion,
#python-accordion {
    /* margin: 1vw; */
}

#python-accordion #box {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

/* icon modification */
#python-icon {
    color: rgba(0, 88, 203, 0.8);
}

#js-icon {
    color: yellow;
}
/**/

#js-title,
#python-title {
    font-family: 'Anton';
    letter-spacing: 0.1vw;
    color: white;
}

.tech-inner-container .popover-text {
    font-size: 1.1vw;
    
    color: white;
    font-weight: 900;
    font-family: 'Roboto Condensed';
}

#introduction-text {
    font-size: 1.1vw;
    margin-left: 0.5vw;
}

@media only screen and (max-width: 1550px) {
    .tech-inner-container {
        width: 70%;
    }

    #introduction-text {
        font-size: 2vw;
        /* border: 3px solid yellow; */
    }

    .tech-inner-container .popover-text {
        font-size: 2vw;
        margin-left: 0.75vw;
    }

    .tech-inner-container #accordion-details {
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width: 450px) {
    .tech-inner-container {
        margin-top: 20vw;
        width: 95%;
    }

    #python-accordion,
    #js-accordion {
        width: 100%;
        /* font-size: var(--mobile-font-size); */
    }

    #js-title,
    #python-title {
        font-size: var(--mobile-header-size);
        
    }

    #python-text,
    #js-text {
        font-size: var(--mobile-font-size);
        /* font-size: 4; */
    }

    #introduction-text {
        font-size: var(--mobile-font-size);
        /* border: 3px solid yellow; */
    }

    #hidden-text {
        font-size: var(--mobile-font-size);
        
    }

    .tech-inner-container #header {
        font-size: 8vw;
        margin: 4vw;
    }
}