.css-container {
    width: 100%;
    min-height: 0vw;
    display: flex;
    /* border: 3px solid red; */
    border-radius: 10px;
    margin-top: 2vw;
    background-color: rgb(70, 70, 70);
}

.css-inner-container {
    width: 100%;
    /* border: 5px solid green; */
    display:flex;
    align-items: center;
    justify-content: center;
}

#css-accordion {
    /* margin: 1vw; */
}

#css-accordion #box {
    display: flex;
    flex-direction: column;
}

#css-title {
    font-family: 'Anton';
    letter-spacing: 0.1vw;
    color: white;
}

#css-text {
    color: white;
    font-family: 'Roboto Condensed';
}

#css-logo {
    color: rgba(29, 88, 210, 0.8);
}

@media only screen and (max-width: 1550px) {
    #css-text {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 450px) {
    #css-title {
        font-size: var(--mobile-header-size);
    }

    #css-text {
        font-size: var(--mobile-font-size);
    }

    #css-accordion {
        width: 100%;
    }
}