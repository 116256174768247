.card-container {
    width: 45vw;
    height: 35vw;
    transition: all 0.3s ease;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    margin-top: 6vw;
    margin-bottom: 4vw;
    margin-left: 3vw;
    /* border: 1px solid yellow; */
}

.container-highlighted {
    transition: all 0.3s ease;
    transform: scale(1.03);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.card-inner-container {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,15,121,1) 52%, rgba(18,73,120,1) 100%);    
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.card-text-holder {
    margin-top: 2vw;
    /* border: 3px solid blue; */
    width: 100%;
    height: auto;
}

#reference-img {
    width: 35vw;
    height: 18vw;
    border-radius: 20px;
    margin-top: -7%;
}

#reference-video {
    width: 35vw;
    height: 18vw;
    border-radius: 20px;
    margin-top: -7%;
}

#card-title {
    color: white;
    font-family: "Anton";
    margin-left: 1vw;
    font-size: 2vw;
}

#card-text {
    color: white;
    font-family: "Roboto Condensed";
    width: 60%;
    font-size: 1.2vw; 
    margin-top: 2.6vw;
    margin-left: 1vw;
}

.card-button-holder {
    height: auto;
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;

}

#card-button {
    background-color: green;
    margin-bottom: -0.5vw;
    height: 3vw;
    width: auto;
    font-size: 0.7vw;
}

@media only screen and (max-width: 1550px) {
    .card-container {
        /* border: 2px solid red; */
        width: 60vw;
        height: 45vw;
        margin-bottom: 4vw;
    }

    #reference-img {
        width: 45vw;
        height: 23vw;
        margin-top: -10%;
    }

    #reference-video {
        width: 45vw;
        height: 23vw;
        margin-top: -10%;
    }

    #card-title {
        font-size: 3.6vw;
    }

    #card-text {
        font-size: 1.6vw; 
    }

    #card-button {
        font-size: 1vw;
        height: 5vw;
        margin-bottom: -3%;
    }
}

@media only screen and (max-width: 450px) {
    .card-container {
        margin-top: 25vw;
        margin-bottom: 13vw;
        margin-left: 5vw;
        /* border: 1px solid green; */
        width: 88vw;
        height: 77vw;
        /* border: 2px solid blue; */
    }

    #card-title {
        font-size: 6vw;
        margin-left: 4vw;
    }

    #reference-img {
        width: 80vw;
        height: 43vw;
        margin-top: -20%;
        border-radius: 15px;
    }

    #reference-video {
        width: 80vw;
        height: 43vw;
        margin-top: -20%;
        border-radius: 15px;
    }

    #card-text {
        width: 80%;
        font-size: 4vw;
        margin-top: 5vw;
        margin-left: 4vw;
    }

    #card-button {
        font-size: 2.4vw;
        height: 9vw;
        margin-bottom: -2vw;
    }
}