.trigger-box {
    display: inline-flex;
    cursor: pointer;
    /* border: 1px solid red; */
    padding-left: 0vw;
    padding-right: 0.25vw;
}

.popup-container {  
    border-radius: 10px;
    /* background-color: gray; */
   
}

.popup-container .hidden-text {
    font-size: 0.9vw;
    font-family: 'Anton';
    color: black;
}

.trigger-box .tech-name {
    font-size: 1.1vw;   
    margin-left: 0.5vw;
    font-family: 'Roboto Condensed';    
    color: white;
}

#trigger-img {
    width: 1.2vw;
    height: 1.2vw;
}

@media only screen and (max-width: 1550px) {
    .trigger-box {
        display: inline-flex;
        /* border: 1px solid yellow; */
        width: 20%;
    }

    .popup-container .hidden-text {
        font-size: 1.4vw;
    }

    .trigger-box .tech-name {
        font-size: 2vw;
        margin-left: 0.3vw;
    }

    #trigger-img  {
        width: 2vw;
        height: 2vw;
    }

}

@media only screen and (max-width: 450px) {
    .trigger-box {
        /* align-items: center; */
        width: 100%;
    }

    .trigger-box .tech-name {
        font-size: var(--mobile-font-size);
    }

    .popup-container .hidden-text {
        font-size: 4vw;
    }    

    #trigger-img {
        width: 5vw;
        height: 5vw;
    }
}