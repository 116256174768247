.contact-outer-container {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: all 1s ease;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40vw;
}

.contact-inner-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 5vw;
    border-radius: 10px;
    /* border: 2px solid green; */
    background-color: black;
}

.contact-title-holder {
    width: 100%;
    height: auto;
    margin-top: 2vw;
    margin-bottom: 2vw;
    display: flex;
    justify-content: center;
}

.contact-email-holder {
    width: 100%;
    min-height: 0vw;
    display: flex;
    flex-direction: column;
    /* border: 3px solid red; */
    /* border-radius: 10px; */
    margin-top: 2vw;
    background-color: rgb(70, 70, 70);
    background-color: black;
    border-radius: 10px;
}

#contact-title {
    color: white;
    font-family: "Roboto Condensed";
    text-align: center;
}

#email-address {
    font-family: "Anton";
    font-size: 3vw;
    color: blue;
    margin-bottom: 4vw;
    
}

.popup-inner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.popup-container {
    /* border: 1px solid red; */

}

@media only screen and (max-width: 450px) {
    .contact-outer-container {
        margin-bottom: 140vw;
        /* transition: max-height 0.7s ease; */
        
    }

    .contact-inner-container {
        margin-top: 70vw;
        width: 90%;
        margin-bottom: 30vw;
    }

    #contact-title {
        font-size: 8vw;
        margin-bottom: 9vw;
        width: 90%;
        text-align: center;

    }

    #email-address {
        font-size: 7vw;
        margin-bottom: 7vw;
    }

    .popup-inner-container {
        border-radius: 15px;
    }

    .popup-container {
        /* border: 1px solid red; */
        padding: 2vw;
    }
}