.ref-outer-container {
    width: 100%;
    max-height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30vw;
}

.ref-inner-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 5vw;
    background-color: rgb(70, 70, 70);
    border-radius: 10px;
    /* border: 2px solid green; */
    max-height: 0; 
    overflow: hidden;
    transition: max-height 1s ease; 
    
}

.carousel-container {
    height: auto;
    width: 100%;
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    /* border: 1px solid green; */
    background-color: rgb(70, 70, 70);
    /* background-color: black; */
}

.personal-site-section,
.webshop-section {
    width: 100%;
    min-height: 0vw;
    display: flex;
    flex-direction: column;
    /* border: 3px solid red; */
    /* border-radius: 10px; */
    margin-top: 2vw;
    background-color: rgb(70, 70, 70);
}

.typedtext-holder {
    width: 100%;
    /* background-color: rgb(70, 70, 70); */
    background-color: black;
    /* border: 1px solid red; */
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    
}

#ref-text {
    font-family: "Roboto Condensed";
    color: white;
    text-align: center;
    width: 70%;
    

}

#website-reference {
    font-family: "Anton";
    font-size: 2vw;
}

.popup-div-A {
    width: 100%;
    height: auto;
    /* border: 1px solid green; */
}

.popup-div-B {
    border-radius: 0 0 10px 10px;
}

.popup-anchor-B {
    margin-bottom: 1vw
}

@media only screen and (max-width: 1550px) {
    
}

@media only screen and (max-width: 450px) {
    .ref-outer-container {
        margin-bottom: 100vw;
    }

    #ref-text {
        font-size: 6vw;
        width: 90%;
    }

    .typedtext-holder {
        /* border: 1px solid red; */
        width: 95%;
        margin: 0;
    }

    .ref-inner-container {
        margin-top: 25vw;
        margin-bottom: 15vw;
        /* border: 2px solid yellow; */
        width: 100%;
    }

    .webshop-section {
        margin-top: 13vw;
    }

    #website-reference {
        font-size: 8vw;
    }

    .popup-anchor-B {
        margin-bottom: 4vw;
    }

    .personal-site-section {
        margin-top: 5vw;
        padding: 1vw;
        align-items: center;
    }

}