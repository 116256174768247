#tsparticles {
    z-index: 0;
    position: absolute;
    height: 30vw;
    /* border: 1px solid red; */
    width: 100%;
    margin-top: 0vw;
}

.particles-container {
    max-height: 5vw;
    /* border: 5px solid green; */
    height: 29vw;
    width: 100%;
}

@media only screen and (max-width: 450px) {
    #tsparticles {
        height: 90vw;
        /* border: 2px solid red; */
    }
}