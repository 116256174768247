.swiper-container {
    width: 100%;
    /* border: 2px solid blue; */
    height: auto;
}

.contact_swiper {
    /* border: 3px solid yellow; */
    width: 70%;
    /* background-color: black; */
    background-color: rgb(70, 70, 70);
}

@media only screen and (max-width: 1550px) {
    .contact_swiper {
        /* border: 2px solid green; */
        width: 81.3%;
        margin-top: 5vw;
    }
}

@media only screen and (max-width: 450px) {


.contact_swiper {
    width: 100%;
    /* border: 2px solid red; */
}

}

