.header-container {
    width: 100%;
    border-bottom: 1px solid rgb(70, 70, 70);
    z-index: 1000;
}

#menu-icon {
    width: 2.5vw;
    height: 2vw;
}

#link-path-container {
    margin: 2vw;
}

.navtag {
    color: white;
    text-decoration: none;
}

.navtag-active {
    color: silver;
    /* text-decoration: underline; */
    background-color: rgba(140, 140, 140, 0.5);
    border-radius: 5px;
    padding: 0.5vw;

}

.navtag,
.navtag-active {
    display: inline flex;
}

#menu-path-title {
    font-family: 'Quicksand';
    font-weight: 900;
    font-size: 1vw;
    letter-spacing: 0.1vw;
}

#face-icon {
    font-size: 1.75vw
}

#mic-img {
    width: 1.5vw;
    height: 1.5vw;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

@media only screen and (max-width: 1500px) {
    #menu-icon {
        width: 3.5vw;
        height: 3vw;
    }
    #menu-path-title {
        font-size: 1.7vw;
    }
}

@media only screen and (max-width: 450px) {
    .header-container {
        position: fixed;
        z-index: 100;
    }

    #menu-icon {
        width: 9vw;
        height: 8vw;
       
    }

    #menu-path-title {
        font-size: 5vw;
        font-weight: 200;
        
    }

    #link-path-container {
        margin: 4.5vw;
    }

    #logo-text {
        font-size: 8vw;
    }

    #face-icon {
        font-size: 6vw;
        /* border: 1px solid red; */
        margin-left: 0vw;
    }

    .navtag-active {
        padding: 1.5vw;
    }   
}